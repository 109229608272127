<template>
    <div class="legal text-left">
        <b-container>
            <div class="text-center my-5">
                <img src="../../assets/svg/buenpuerto-logo.svg" width="240">
            </div>
            <div class="text-right text-sm mb-5">Las presentes Condiciones se encuentran actualizadas a fecha 23/09/2022</div>
            <h5 class="bold mb-5">AVISO LEGAL</h5>
            <div class="legal-block">
                <div class="title">
                    TITULAR
                </div>
                <p>
                    En cumplimiento de lo establecido en la Ley 34/2002 de Servicios de la Sociedad de la Información y de Comercio Electrónico, te informamos que la Web <a href="https://somosbuenpuerto.com">https://somosbuenpuerto.com</a> es propiedad de BUEN PUERTO RH, S.L., domiciliada en Altos del club de polo, 32. 28692 Villanueva de la cañada (Madrid), inscrita en el Registro Mercantil de Madrid al Tomo 41126, Folio 41, Sección 8ª, Hoja nº M-729217, Inscripción 1ª y con N.I.F. número B72488927 (en adelante, SOMOS BUEN PUERTO).
                </p>
                <p>
                    Puedes ponerte en contacto con nosotros enviando un correo electrónico a: <a href="mailto:talento@somosbuenpuerto.com">talento@somosbuenpuerto.com</a> o <a href="mailto:empresas@somosbuenpuerto.com">empresas@somosbuenpuerto.com</a>.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    OBJETO
                </div>
                <p>
                    A través de la Web <a href="https://somosbuenpuerto.com">https://somosbuenpuerto.com</a> (en adelante la Web) informamos a los visitantes y usuarios sobre los servicios que SOMOS BUEN PUERTO ofrece.
                </p>
                <p>
                    SOMOS BUEN PUERTO es una iniciativa que pretende conectar a aquellas empresas que necesitan cubrir determinados puestos de trabajo con talento que está en búsqueda de empleo.
                </p>
                <p>
                    Nuestra Web contendrá además el enlace a <a href="https://www.linkedin.com/company/buenpuerto/?viewAsMember=true" target="_blank">nuestro perfil en Linkedin</a>. Obviamente el acceso y uso de Linkedin se regirá por sus propios términos legales, por lo que quedarás obligado a respetar mismos en relación con el registro y uso del sitio, así como en relación con las condiciones de privacidad y propiedad intelectual. Te recomendamos, por tanto, que te leas con detenimiento dichas condiciones generales. SOMOS BUEN PUERTO no se responsabilizará de su contenido ni de su correcto funcionamiento.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    CONDICIONES DE ACCESO Y USO
                </div>
                <p>
                    Mediante el acceso y utilización de la Web, se entiende que manifiestas de forma expresa tu aceptación al contenido de todas y cada una de las presentes Condiciones en la versión publicada en el momento del acceso. Se entenderá igualmente que aceptas además las siguientes condiciones:
                    <ul class>
                        <li><router-link :to="{ name: 'cookies' }" class="black mx-2">Política de cookies</router-link></li>
                        <li><router-link :to="{ name: 'privacidad' }" class="black mx-2">Política de privacidad</router-link></li>
                    </ul>
                </p>
                <p>
                    Por favor, lee previamente el presente Aviso Legal y si no estás conforme con el mismo, no hagas uso de la Web.
                </p>
                <p>
                    La utilización de ciertos contenidos y/o servicios ofrecidos a través de nuestra Web puede encontrarse sometida a condiciones particulares propias (en adelante, las “Condiciones Particulares”) que, según los casos, sustituirán, completarán y/o modificarán las presentes Condiciones Generales. Recomendamos, por tanto, que antes de usar tales contenidos y/o servicios, te leas en su caso las correspondientes Condiciones Particulares.
                </p>
                <p>
                    Comentarte también que determinadas acciones puedan estar desarrolladas por terceros (colaboradores de SOMOS BUEN PUERTO) por lo que, en caso de participar en éstas, tendrás que leer y en su caso aceptar las condiciones que apliquen y que habrán sido determinadas por el responsable concreto.
                </p>
                <p>
                    El acceso a la Web se realiza bajo tu propia y exclusiva responsabilidad, por lo que (responderás en todo caso de los daños y perjuicios que pudieras causar.
                </p>
                <p>
                    Nos reservamos el derecho de modificar el contenido de las presentes Condiciones en cualquier momento para adaptarnos a cambios operativos, técnicos o legislativos.
                </p>
                <p>
                    Te comprometes a utilizar la Web, los contenidos y servicios en ella ofrecidos de conformidad con la ley, las presentes Condiciones, la buena fe, las buenas costumbres y el orden público. También te obligas a no utilizar la Web o los servicios que se presten a través de ella con fines o efectos ilícitos o lesivos para los intereses o derechos de terceros, o que de cualquier forma puedan dañar, inutilizar o deteriorar la Web o sus servicios o impedir un normal disfrute del mismo por otros.
                </p>
                <p>
                    Te comprometes igualmente a abstenerte de utilizar la Web o la información obtenida a través de la misma, para remitir publicidad, comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como de comercializar o divulgar de cualquier modo dicha información.
                </p>
                <p>
                    En caso de realizar cualquiera de estas acciones podremos ejercitar las acciones legales que nos correspondan.
                </p>
                <p>
                    El acceso a nuestra Web tiene carácter gratuito y no requiere previa suscripción o registro. Toda la información incluida en la misma tiene fines exclusivamente informativos.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    CONTENIDOS DE LA WEB – PROPIEDAD INTELECTUAL
                </div>
                <p>
                    Todos los contenidos de la Web, salvo que se indique lo contrario, son titularidad exclusiva de SOMOS BUEN PUERTO o de terceros que han autorizado su inclusión en la misma.
                </p>
                <p>
                    Igualmente, todos los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en la Web están protegidos por la ley estando prohibida su reproducción, imitación, utilización o inserción sin la debida autorización por parte de SOMOS BUEN PUERTO.
                </p>
                <p>
                    Reconoces que la reproducción, distribución, comercialización o transformación no autorizada de los elementos indicados puede constituir una infracción de los derechos de propiedad intelectual y/o industrial de SOMOS BUEN PUERTO o de sus titulares.
                </p>
                <p>
                    Se prohíbe por tanto, la reproducción, transformación, comunicación pública, distribución, puesta a disposición del público, y en general, cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de nuestra Web, así como de su diseño y forma de presentación de los contenidos, sin contar para ello con la correspondiente autorización del titular de los derechos o salvo si se encontrara legalmente permitido o fuera acorde con la naturaleza del propio contenido.
                </p>
                <p>
                    El acceso a la Web no supone en ningún caso, adquisición por tu parte de ningún derecho de propiedad sobre los contenidos que figuran en ésta.
                </p>
                <p>
                    No podrás utilizar los contenidos ofrecidos en nuestra Web, para finalidades distintas a las establecidas en las presentes condiciones, y en su caso, en las condiciones particulares que regulen la utilización de determinadas funcionalidades de la misma.
                </p>
                <p>
                    Se prohíbe la inclusión de enlaces o cualquier tipo de conexión a nuestra Web desde sitios web de terceros sin nuestro consentimiento expreso y por escrito. Si se te ocurre que puede ser buena idea presentarnos en tu Web y realizar un enlace a la de SOMOS BUEN PUERTO, consúltanos antes.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    LIMITACIÓN DE RESPONSABILIDAD
                </div>
                <div class="subtitle">
                    A) Responsabilidad por el uso de la Web
                </div>
                <p>
                    Como usuario tú serás el único responsable de las infracciones en las que puedas incurrir o de los perjuicios que pueda causar por la utilización de la Web, quedando SOMOS BUEN PUERTO exonerada de cualquier clase de responsabilidad que se pudiera derivar de tus propias acciones.
                </p>
                <p>
                    Por ello, serás el único responsable frente a cualquier reclamación o acción legal, judicial o extrajudicial, iniciada por terceras personas contra SOMOS BUEN PUERTO cuyo origen estuviera en tus actividades o acciones. En este caso, debes saber que asumirás cuantos gastos, costes e indemnizaciones sean irrogados a SOMOS BUEN PUERTO con motivo de tales reclamaciones o acciones legales.
                </p>
                <div class="subtitle">
                    B) Responsabilidad por el funcionamiento de la Web
                </div>
                <p>
                    SOMOS BUEN PUERTO realiza los máximos esfuerzos para garantizar que la navegación a través de la Web se realice en las mejores condiciones, pero no podemos controlar lo que se escapa de nuestro control real, por eso, SOMOS BUEN PUERTO quedará exonerada de toda responsabilidad que se pudiera derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo del sistema electrónico, motivado por causas ajenas a SOMOS BUEN PUERTO.
                </p>
                <p>
                    Asimismo, SOMOS BUEN PUERTO también excluye cualquier responsabilidad que pudiera derivarse por retrasos o bloqueos en el funcionamiento operativo de este sistema electrónico causado por deficiencias o sobre carga en las líneas telefónicas o en Internet, así como de daños causados por terceras personas mediante intromisiones ilegitimas fuera del control de SOMOS BUEN PUERTO.
                </p>
                <div class="subtitle">
                    C) Responsabilidad por links
                </div>
                <p>
                    La presente Web o/y las diferentes acciones que en la misma se desarrollen, pueden incorporar enlaces a otros sitios o páginas, siendo los titulares de tales sitios o páginas responsables de los contenidos en ellas alojados.
                </p>
                <p>
                    Además, la existencia de hiperenlaces en páginas web de terceros que permitan el acceso a esta Web no implicará en ningún caso la existencia de relaciones comerciales o mercantiles con el titular de la página web donde se establezca el hiperenlace, ni la aceptación por parte de SOMOS BUEN PUERTO de sus contenidos o servicios.
                </p>
                <p>
                    SOMOS BUEN PUERTO declina toda responsabilidad respecto a la información que se halle fuera de su propia Web.
                </p>
                <p>
                    SOMOS BUEN PUERTO se exonera de toda responsabilidad por el correcto funcionamiento de tales enlaces, del resultado obtenido a través de dichos enlaces, de la veracidad y licitud del contenido o información a la que se puede acceder, así como de los perjuicios que pueda sufrir en virtud de la información encontrada en la web enlazada.
                </p>
                <div class="subtitle">
                    D) Responsabilidad por publicidad
                </div>
                <p>
                    La Web de SOMOS BUEN PUERTO no alberga contenidos publicitarios de terceros pero si en algún momento SOMOS BUEN PUERTO decidiera incluir en su Web publicidad de terceros, los anunciantes y patrocinadores serán los únicos responsables de asegurarse que el material remitido para su inclusión en la Web cumple con las leyes que en cada caso puedan ser de aplicación. SOMOS BUEN PUERTO no será responsable de ningún error, inexactitud o irregularidad que puedan albergar los contenidos publicitarios de terceros o de los patrocinadores.
                </p>
                <p>
                    En todo caso, para interponer cualquier reclamación relacionada con los contenidos publicitarios en su caso insertados en esta Web podrá ponerse en contacto con SOMOS BUEN PUERTO a través de cualquiera de los medios facilitados al inicio del presente documento.
                </p>
            </div>
            <div class="legal-block">
                <div class="title">
                    INTERPRETACIÓN, LEGISLACIÓN APLICABLE Y FUERO
                </div>
                <p>
                    Cualquier controversia surgida de la interpretación o ejecución del presente Aviso Legal se interpretará bajo la legislación española.
                </p>
                <p>
                    Las presentes Condiciones están redactadas en castellano. En caso de cualquier discrepancia entre el contenido de la versión en castellano y cualquiera de las versiones de las presentes Condiciones que pudieran ser traducidas, prevalecerá la versión en castellano.
                </p>
                <p>
                    Cualquier cláusula o disposición del presente Aviso Legal que sea o devenga ilegal, inválida o inexigible será excluida del mismo y será considerada inaplicable en lo que alcance a tal ilegalidad, invalidez o inexigibilidad, y será sustituida por otra que se asemeje lo más posible a la anterior, pero no afectará o perjudicará a las restantes disposiciones, las cuales quedarán al margen de cualquier cláusula o disposición ilegal, inválida o inexigible y permanecerán por el contrario con plena vigencia y efecto.
                </p>
                <p>
                    Asimismo, SOMOS BUEN PUERTO y el usuario, con renuncia a cualquier otro fuero, se someten al de los juzgados y tribunales del domicilio del usuario para cualquier controversia que pudiera surgir.
                </p>
            </div>
        </b-container>
    </div>
</template>

<script>
//import NavBar from '@/components/NavBar'

export default {
    name: 'AvisoLegal',
    components: {
    },
    methods: {
        getLink(route) {
            return `<a href="${window.location.origin}/${route}">${window.location.origin}/${route}</a>`
        }
    }
}
</script>